import React, { useState, useEffect } from "react";
import * as axios from 'axios';
import * as moment from 'moment';
import './LatestPhoto.css';

const LatestPhoto = () => {
  const [uploadTime, setUploadTime] = useState([]);

  useEffect(() => {
    setUploadTime(null);

    axios.get("https://f74hlnacr8.execute-api.us-east-1.amazonaws.com/prod/latest-photo").then(response => {
      setUploadTime(moment(response.data.latestPhotoTimestamp).format('LL LT'));
    });
  }, []);

  return (
    <div>
      <h1>Modera Pool Status</h1>
      <p>Last Updated At: {uploadTime}</p>
      <img className='LatestPhoto-image' src='./static/media/pool/latest/latest.jpg'></img>
    </div>
  );
};

export default LatestPhoto;