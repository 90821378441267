import React from 'react';
import './App.css';
import LatestPhoto from './LatestPhoto/LatestPhoto';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <LatestPhoto></LatestPhoto>
      </header>
    </div>
  );
}

export default App;
